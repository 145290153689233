
:root:root {

  /* // Primary #181e21
  // P light #3e4548
  // P dark  #000000
  // Seconda #febd00
  // S light #ffef4e
  // S  Dark #c68d00 */
  /* background: '#000',
  surface: '#3e4548',
  primary: '#181e21',
  secondary: '#febd00' */
  --mdc-theme-surface: #181e21;
  --mdc-theme-on-surface: #fff;
  --mdc-theme-primary: #181e21;
  --mdc-theme-secondary: #febd00;
  --mdc-theme-background: #3e4548;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--mdc-theme-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mdc-drawer.mdc-drawer {
  background-color: var(--mdc-theme-surface);
  color: var(--mdc-theme-on-surface);
}

.mdc-card.mdc-card {
  background-color: var(--mdc-theme-surface);
  color: var(--mdc-theme-on-surface);
}

.mdc-list .rmwc-collapsible-list .mdc-list-item__primary-text::before {
  height: 0;
}


.mdc-list .rmwc-collapsible-list .mdc-list-item {
  padding: 4px 16px;
}


.mdc-list .rmwc-collapsible-list + .rmwc-collapsible-list {
  border-top: 1px solid var(--mdc-theme-background);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-on-surface) !important;
}
.mdc-text-field.mdc-text-field--outlined .mdc-text-field__input,
.mdc-text-field:not(.mdc-text-field--disabled).mdc-text-field--outlined .mdc-text-field__input::placeholder ,
.mdc-text-field.mdc-text-field--outlined .mdc-floating-label {
  caret-color: var(--mdc-theme-on-surface) !important;
  color: var(--mdc-theme-on-surface) !important;
}

.mdc-text-field--textarea.mdc-text-field--textarea.mdc-text-field--disabled {
  background-color: transparent;
}
