.wing-night-overlay {
  width: 435px;
  margin: 0;
  margin-right: 50px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu {
  color: white;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  width: 80%;
  margin: auto;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.price {
  display: block;
}

.menu-item {
  width: 100%;
  margin: 0;
}

.spice {
  color: red;
}

.menu-item .spice {
  display: block;
}

.bitTotal {
  margin-top: 10px;
  text-align: center;
  width: 70%;
}
.total {
  margin-top: 20px;
  color: #febd00 !important;
  width: 60%;
}

.bitProgressBar {
  height: 5px !important;
}

.progressBar {
  height: 20px;
  margin: auto;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #333;
  position: relative;
  width: 100%;
}

.progressBarInner {
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
  height: 100%;
  background: rgba(255, 0, 0, .7);
  background-image: linear-gradient(145deg, #febd00 25%, #000000 25%, #000000 50%, #febd00 50%, #febd00 75%, #000000 75%, #000000 100%);
  background-size: 34.87px 24.42px;
  background-position-x: right;
}

.bitProgressBar > .progressBarInner {
  background-image: linear-gradient(145deg, #ff0000 25%, #cc0000 25%, #cc0000 50%, #ff0000 50%, #ff0000 75%, #cc0000 75%, #cc0000 100%);
}

img {
  margin-top: 20px;
  max-height: 200px;
  max-width: 200px;
}
